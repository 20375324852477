<template>
    <div class="dp-custom-month-year-wrapper">
        <button
            v-if="showLeftIcon"
            class="dp-custom-icon-btn"
            type="button"
            @click="onPrev"
        >
            <icon class="w-4 h-4 transform rotate-180" name="chevronRight"/>
        </button>
        <div class="dp-custom-month-year">
            <select
                class="dp-custom-select-input"
                :value="month"
                @change="$emit('update:month', +$event.target.value)"
            >
                <option v-for="m in months" :key="m.value" :value="m.value">
                    {{ m.text }}
                </option>
            </select>
            <select
                class="dp-custom-select-input"
                :value="year"
                @change="$emit('update:year', +$event.target.value)"
            >
                <option v-for="y in years" :key="y.value" :value="y.value">
                    {{ y.text }}
                </option>
            </select>
        </div>
        <button
            v-if="showRightIcon"
            class="dp-custom-icon-btn right"
            type="button"
            @click="onNext"
        >
            <icon class="w-4 h-4" name="chevronRight"/>
        </button>
    </div>
</template>

<script>
    import Icon from "@/components/ui/Icon";

    export default {
        components: { Icon },
        props: {
            months: {
                type: Array,
                default: () => []
            },
            years: {
                type: Array,
                default: () => []
            },
            filters: {
                type: Object,
                default: null
            },
            monthPicker: {
                type: Boolean,
                default: false
            },
            month: {
                type: Number,
                default: 0
            },
            year: {
                type: Number,
                default: 0
            },
            customProps: {
                type: Object,
                default: null
            }
        },
        emits: ['update:month', 'update:year'],
        setup(props, { emit }) {
            const updateMonthYear = (month, year) => {
                emit('update:month', month);
                emit('update:year', year);
            };

            const onNext = () => {
                let month = props.month;
                let year = props.year;
                if (props.month === 11) {
                    month = 0;
                    year = props.year + 1;
                } else {
                    month += 1;
                }
                updateMonthYear(month, year);
            };

            const onPrev = () => {
                let month = props.month;
                let year = props.year;
                if (props.month === 0) {
                    month = 11;
                    year = props.year - 1;
                } else {
                    month -= 1;
                }
                updateMonthYear(month, year);
            };

            return {
                onNext,
                onPrev,
            };
        },
        computed: {
            showLeftIcon(){
                const { multiCalendars, multiCalendarsSolo, instance } = this.$attrs;
                if (multiCalendars) {
                    return !multiCalendarsSolo ? instance === 0 : true;
                }
                return true;
            },
            showRightIcon() {
                const { multiCalendars, multiCalendarsSolo, instance } = this.$attrs;

                if (multiCalendars) {
                    return !multiCalendarsSolo ? instance === multiCalendars - 1 : true;
                }
                return true;
            },
        },
    }
</script>

<style scoped>
    .dp-custom-month-year-wrapper {
        @apply relative flex items-center justify-center;
        height: 2rem;
    }

    .dp-custom-month-year {
        @apply px-6;
    }

    .dp-custom-icon-btn {
        @apply flex items-center text-gray-400 outline-none absolute top-0 bottom-0 left-0 px-2;
    }

    .dp-custom-icon-btn.right {
        left: initial;
        right: 0;
    }

    .dp-custom-select-input {
        margin: 5px 3px;
        padding: 5px;
        width: auto;
        background: none;
        border: 0;
        outline: none;
    }

</style>
